@font-face {
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nyKS6xpg.ttf) format('truetype');
}
body {
  background: url("../images/rotowidth.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background: cover;
  background-attachment: fixed;
}
.tblpostavke td {
  background-color: #f9f9f9;
  border: 3px solid white;
  padding: 5px;
}
.ant-btn-primary-custom {
  border-color: #2f6561 !important;
  background: #14524d !important;
}
.title-label {
  text-align: center;
  color: #aaaaaa;
}
.period-label {
  font-size: 25px;
  background: #14524d00;
  font-weight: bold;
  color: #298a8f;
  text-align: center;
}
.mtable {
  background: #ebebeb;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
}
.mrow {
  border-bottom: 1px solid gray;
}
.button-garage {
  border: 1px solid gray;
  width: 200px;
  padding: 20px;
  margin: 5px;
  font-size: 20px;
  border-radius: 3px;
  cursor: pointer;
}
.link {
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}
.title-buttons {
  float: right;
  margin-top: -32px;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 2px;
}
/*scrooooollllbar*/
/* width */
/*scrooooollllbar*/
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
.title {
  color: #14524d;
  font-size: bol;
  font-size: 20px;
  font-family: 'Orbitron';
  font-size: 40px;
}
.subtitle {
  color: #a8abaa;
  margin-top: -31px;
}
.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.logoCollapsed {
  color: white;
  padding: 20px;
  font-size: 8px;
  font-family: "orbitron";
  height: 61px;
}
.izvjestaj-preuzmi-button {
  margin-top: 20px;
}
.menu-logo {
  font-family: "orbitron";
}
#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}
#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}
#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
.site-layout .site-layout-background {
  background: #ffffff8f !important;
}
.ant-layout-header {
  padding-left: 20px !important;
}
.item {
  cursor: pointer;
}
.item:hover {
  color: black;
  font-weight: bold;
}
.row-item {
  border-bottom: 1px solid #e9e9e9;
  transition: 0.1s;
  cursor: pointer;
  padding: 3px;
}
.row-item:hover {
  background-color: #fcfcfc;
  color: black;
  font-weight: bold;
}
.spinner {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: #d1d1d1;
  z-index: 9999;
}
.divider {
  width: 100%;
  border-bottom: 1px solid #e5e1e1;
  margin-top: -10px;
}
.spinner-icon {
  font-size: 80px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 80px;
  height: 130px;
  -webkit-animation-name: spin;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.report-card {
  margin: 10px !important;
  display: inline-block !important;
  height: 200px;
}
.transparent {
  opacity: 0.5;
}
.header {
  row-gap: 0px;
  font-size: 10px !important;
  font-weight: bold !important;
  color: white !important;
  background: #ffffff;
  background: linear-gradient(90deg, #ffffff 0%, #001529 5%, #001529 95%, #ffffff 100%);
}
.pregled-klijenata-fixed {
  border: 1px solid #d6d6d6 !important;
  font-size: 12px;
  padding: 10px;
  background-color: #f0f2f5;
  color: #616161;
  font-weight: bold;
}
.pregled-klijenata {
  width: 100%;
  table-layout: fixed;
}
.pregled-klijenata thead td {
  border: 1px solid #d6d6d6 !important;
  font-size: 12px;
  padding: 10px;
  background-color: #f0f2f5;
  color: #616161;
  font-weight: bold;
}
.label {
  background-color: #f0f2f5 !important;
  cursor: default;
}
.info-label {
  color: #d4d4d4;
}
.pregled-klijenata-tr:hover {
  background-color: aqua;
}
.home-button {
  width: 80%;
  margin-top: 10px;
  height: 100px !important;
  border-radius: 10px !important;
  background-color: aliceblue !important;
  border: 1px solid #eaeaea !important;
  font-size: 20px !important;
  color: #214171 !important;
}
.satnica-dan-row {
  border-bottom: 1px solid #dedede;
  cursor: pointer;
  background: #fbfbe5;
}
.satnica-dan-cell {
  padding: 10px;
  border-left: 1px solid #dedede;
}
.satnica-header {
  grid-row-gap: 0px;
  row-gap: 0px;
  font-size: 10px !important;
  font-weight: bold !important;
  background: #f1f1f1 !important;
  border: 1px solid #e6e6e6;
}
.today {
  background-color: aliceblue;
}
.satnica-dan-row:hover {
  background-color: #f7f7a7;
  -webkit-transition: all 700ms;
  -ms-transform: all 700ms;
}
.pick-button {
  width: 100%;
  margin-top: 5px;
  color: #181818 !important;
  background: #fafafa !important;
  cursor: pointer !important;
  font-weight: bold;
}
.m-satnica-dan-row {
  border-bottom: 5px solid #ececec;
  padding-bottom: 5px;
  cursor: pointer;
}
.list-picker {
  border-bottom: 1px solid #ececec;
  padding-bottom: 5px;
  cursor: pointer;
  margin: 5px;
  width: 100%;
  padding: 5px;
}
.list-picker:hover {
  font-weight: bold;
}
.satnica-row {
  border-bottom: 1px solid #ececec;
  padding-bottom: 5px;
  cursor: pointer;
  margin: 5px;
  width: 100%;
  padding: 8px;
  background: #4db6ac0f;
  min-height: 55px;
  border-radius: 5px;
  transition: 0.3s;
}
.satnica-row:hover {
  /* font-weight: bold; */
  background: #4db6ac33;
}
.button-link {
  border: none !important;
  box-shadow: none !important;
  text-decoration: underline !important;
}
.button-link span {
  text-decoration: underline;
}
button p {
  text-overflow: ellipsis;
  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}
.hamby {
  background-color: #4db6ac;
  float: right;
}
.hamby-item {
  width: 100%;
  background-color: none !important;
  background: none !important;
  color: #2c3742 !important;
  font-weight: bold !important;
  font-size: 20px !important;
  padding: 25px;
  text-align: center;
  border-bottom: 1px solid #eaeaea;
}
.hamby-content {
  position: absolute;
  top: 50px;
  background-color: white;
  width: 100vw;
  height: calc(100vh - 50px);
  z-index: 10000;
  color: #2c3742;
}
.add-button {
  text-align: center;
  height: 64px;
  cursor: pointer;
  margin-top: 20px;
}
.add-button:active {
  opacity: 0.8;
}
.flat-button {
  margin-bottom: 20px !important;
  width: 100% !important;
  border: none !important;
  /* border-color: #4e92ce; */
  font-size: 16px !important;
  font-weight: bold !important;
  color: #1d578c !important;
  overflow: hidden;
}
.grey {
  color: #dddddd;
}
.center {
  text-align: center !important;
  display: block !important;
}
.left {
  text-align: left !important;
}
.right {
  text-align: right !important;
}
@media only screen and (max-width: 600px) {
  .ant-layout-content {
    margin: 0px !important;
    padding: 0px !important;
  }
  .flat-button {
    font-size: 12px !important;
  }
  .dodaj-stavku {
    width: 100%;
    height: 50p !important;
    font-weight: bold;
  }
  .ant-btn {
    padding-bottom: 40px !important;
    padding-top: 20px !important;
    border-radius: 10px !important;
    color: rgba(255, 255, 255, 0.85) !important;
    background: #2c3742 !important;
    border-radius: 5px !important;
    outline: none !important;
  }
  .pick-button {
    width: 100%;
    margin-top: 5px;
    color: #181818 !important;
    background: #fafafa !important;
    cursor: pointer !important;
    font-weight: bold;
    border-radius: 0px !important;
    outline: none !important;
  }
}
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width: 100%;
}
.styled-table thead tr {
  background-color: #f8f8f9;
  color: #504a4a;
  text-align: left;
}
.styled-table th,
.styled-table td {
  padding: 12px 15px;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
  transition: 0.3s;
}
.styled-table tbody tr:hover {
  background-color: #f0fffc;
}
.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
  cursor: pointer;
  transition: 0.3s;
}
.styled-table tbody tr:nth-of-type(even):hover {
  background-color: #f0fffc;
}
.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}
.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}
.semaphore-red {
  animation: blinker 1.5s infinite;
  color: red;
  background-color: red;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-left: 10px;
  margin-top: -15px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px;
  -webkit-animation: blinkRed 1s infinite;
  -moz-animation: blinkRed 1s infinite;
  -ms-animation: blinkRed 1s infinite;
  -o-animation: blinkRed 1s infinite;
  animation: blinkRed 1s infinite;
}
.semaphore-small {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-left: 10px;
  margin-top: -15px;
}
.semaphore-small-red {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-left: 10px;
  margin-top: -15px;
  color: red;
  background-color: red;
}
.semaphore-small-yellow {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-left: 10px;
  margin-top: -15px;
  color: #f7f304;
  background-color: #f7f304;
}
.semaphore-small-green {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-left: 10px;
  margin-top: -15px;
  color: #65f704;
  background-color: #65f704;
}
@-webkit-keyframes blinkRed {
  from {
    background-color: #F00;
  }
  50% {
    background-color: #A00;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;
  }
  to {
    background-color: #F00;
  }
}
@-moz-keyframes blinkRed {
  from {
    background-color: #F00;
  }
  50% {
    background-color: #A00;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;
  }
  to {
    background-color: #F00;
  }
}
@-ms-keyframes blinkRed {
  from {
    background-color: #F00;
  }
  50% {
    background-color: #A00;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;
  }
  to {
    background-color: #F00;
  }
}
@-o-keyframes blinkRed {
  from {
    background-color: #F00;
  }
  50% {
    background-color: #A00;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;
  }
  to {
    background-color: #F00;
  }
}
@keyframes blinkRed {
  from {
    background-color: #F00;
  }
  50% {
    background-color: #A00;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;
  }
  to {
    background-color: #F00;
  }
}
@keyframes blinker {
  from {
    opacity: 0.7;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}
