#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}
#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}
#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
.ant-layout-header {
  padding-left: 20px!important;
}
.ant-layout {
  min-height: 100vh!important;
}
@media only screen and (max-width: 1200px) {
  .ant-layout {
    min-height: 100vh!important;
  }
}
.ant-menu-inline-collapsed {
  width: 50px!important;
}
.ant-input-number {
  width: 100%!important;
  text-align: right!important;
}
.ant-input-number-input {
  text-align: right!important;
  padding-right: 25px!important;
}
.ant-modal-header {
  padding: 16px 24px;
  color: rgba(255, 255, 255, 0.85) !important;
  background: #f3f3f3 !important;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.ant-modal-title {
  margin: 0;
  color: rgba(45, 45, 45, 0.85) !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.ant-layout-sider {
  background: #14524d !important;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: #14524d !important;
}
.ant-layout-sider {
  background: #14524d !important;
}
.ant-layout-sider {
  background: #14524d !important;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #33adb700 !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #3c8e9f !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn :hover {
  color: #3c8e9f !important;
}
.ant-tabs-ink-bar {
  background: #a5bcc1 !important;
}
.ant-layout {
  background: #f0f2f5ab !important;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  color: #15b186 !important;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: #c7c7c7 !important;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #c7c7c7 !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #3dcbe7 !important;
  border-color: #13ada7 !important;
}
.css-1aznpnh-MuiSlider-root {
  color: #2dacb3 !important;
}
.ant-input-number-input {
  text-align: left!important;
}
.ant-switch-checked {
  background-color: #5eada6 !important;
}
